import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import { Hotel } from "../../entitites";
import useSearchStore from "../../store/searchState";
import StarRating from "../StarRating/StarRating";

export interface StayCardProps {
    className?: string;
    data: Hotel;
    size?: "default" | "small";
}

const StayCardHotels: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data,
}) => {
  const {
    id,
    title,
    address,
    price,
    pictures,
    star_rating,
    rating,
    reviewsCount
  } = data;
  const dates = useSearchStore((state) => state.dates);

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={pictures}
          href={`/hotel/${id}`}
        />
        <BtnLikeIcon isLiked={false} className="absolute right-3 top-3 z-[1]" />
        {/* {price && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

    const renderContent = () => {
        return (
            <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
                <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {/* {'-----'} · {0} beds */}
          </span>
                    <div className="flex items-center space-x-2">
                        {/* {null && <Badge name="ADS" color="green"/>} */}
                        <h2
                            className={` font-medium capitalize ${
                                size === "default" ? "text-lg" : "text-base"
                            }`}
                        >
                            <span className="line-clamp-1">{title}</span>
                        </h2>
                    </div>
                    <StarRating
                      className="flex items-center space-x-1"
                      point={star_rating}
                    />
                    <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                        {size === "default" && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.5}
                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.5}
                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                            </svg>
                        )}
                        <span className="line-clamp-1">{address}</span>
                    </div>
                </div>
                <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
                <div className="flex justify-between items-center">
                  <span className="text-base font-semibold">
                    {size === "default" && (
                      <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                        from
                      </span>
                    )}
                    {` `}
                    {price + '\u{20AC}'}
                    {` `}
                    {size === "default" && (
                      <>  <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                            /
                          </span>
                          {` `}
                          {dates.days}
                          {` `}
                          <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                              night{ dates.days > 1 ? 's' : '' }
                          </span></>
                      )}
                  </span>
                  {!!rating.rating && (
                      <StartRating reviewCount={reviewsCount} point={rating.rating}/>
                  )}
                </div>
            </div>
        );
    };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={`/hotel/${id}`}>{renderContent()}</Link>
    </div>
  );
};

export default StayCardHotels;
