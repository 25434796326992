import React, { FC } from "react";
import { Link } from "react-router-dom";
import useSearchStore from "../../store/searchState";
import { loadHotels } from "../../infra/store/hotels";
import useHotelsStore from "../../store/hotelsState";

interface Props {
  href?: string;
}

const ButtonSubmit: FC<Props> = ({ href = "/listing-stay" }) => {
  const location = useSearchStore((state) => state.location);
  const setLocationError = useSearchStore((state) => state.setLocationError);
  const setLocationUI = useSearchStore((state) => state.setLocationUI);
  const dates = useSearchStore((state) => state.dates);
  const guests = useSearchStore((state) => state.guests);
  const setHotels = useHotelsStore((state) => state.setHotels);
  const setTrip = useHotelsStore((state) => state.setTrip);
  const setScrollToSection = useSearchStore((state) => state.setScrollToSection);
  const setLoading = useHotelsStore((state) => state.setLoading);
  const setRangePrices = useHotelsStore((state) => state.setRangePrices);
  const setHasMoreHotels = useHotelsStore((state) => state.setHasMoreHotels);
  const setStarsRatings = useHotelsStore((state) => state.setStarsRatings);
  const defaultPrices = [0, 2000];
  const defaultStarsRatings = [0, 1, 2, 3, 4, 5];
  const setButtonSubmit = useSearchStore((state) => state.setButtonSubmit);

  const handleSubmit = async () => {
    if (location.id === 0) { 
      setLocationError(true);
      return;
    }
    setScrollToSection(true);
    setLoading(true);
    try {
      // повертаємо в дефолт значення всіх параметрів перед пошуком з головної кнопки
      setLocationUI(location);
      setRangePrices(defaultPrices);
      setTrip({ offset: 0, limit: 16 });
      setStarsRatings(defaultStarsRatings);
      setButtonSubmit(true);
      const res = await loadHotels({
        endDate: dates.endDate,
        startDate: dates.startDate,
        item_id: location.id,
        adults: guests.adults,
        children: new Array(guests.children + guests.infants).fill(8),
        limit: 16,
        offset: 0,
        sort: null,
      }, { stars: defaultStarsRatings, priceFrom: defaultPrices[0], priceTo: defaultPrices[1] });
      setHotels(res);
      setHasMoreHotels(true);
      setTrip({ offset: 16, limit: 16 })
    } catch (error) {
      console.error("Error loading hotels:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Link
      to={href}
      type="button"
      className="h-14 md:h-16 w-full md:w-16 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none"
      onClick={handleSubmit} 
    >
      <span className="mr-3 md:hidden">Search</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    </Link>
  );
};

export default ButtonSubmit;
