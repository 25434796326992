import { create } from 'zustand'
import hotelApi from "../api/hotel";
import { Hotel, Review, Room } from "entitites";
import useSearchStore from "./searchState";

interface HotelState {
    hotel: Hotel | null;
    selectedRoom: Room | null;
    reviews: {
        items: Review[];
        limit: number;
        offset: number;
        isLoadingReviews: boolean;
    };
    isLoadingHotel: boolean;
    error: any;
    getHotel: (data: any) => void;
    getHotelReviews: (data: GetHotelReviewsData) => void;
    setRoom: (selectedRoom: Room) => void;
    reset: () => void;
}

const initialState = {
    hotel: null,
    selectedRoom: null,
    reviews: {
        items: [],
        limit: 5,
        offset: 0,
        isLoadingReviews: false,
    },
    isLoadingHotel: true,
    error: null,
}

interface GetHotelReviewsData {
    hotelId: string,
    limit: number,
    offset: number
}


const useHotelStore = create<HotelState>((set, get) => ({
    ...initialState,
    getHotel: async (data: any) => {
        set({isLoadingHotel: true});
        const searchState = useSearchStore.getState()


        try {
            const [hotel, reviews] = await Promise.all([
                hotelApi.getHotel({
                    endDate: searchState.dates.endDate,
                    startDate: searchState.dates.startDate,
                    item_id: searchState.location,
                    hotel: data.hotelId,
                    adults: searchState.guests.adults,
                    children: new Array(searchState.guests.children + searchState.guests.infants).fill(8),
                    offset: 0,
                    limit: 10,
                }),
                hotelApi.getReviews(data.hotelId, get().reviews.limit, get().reviews.offset),
                // hotelApi.getRoomPhotos(data.hotelId)
            ]);
            const offset = reviews.items.length
            set({
                hotel, reviews: {
                    ...get().reviews,
                    items: [...get().reviews.items, ...reviews.items],
                    offset: offset,
                }, isLoadingHotel: false,
            })
        } catch (error) {
            set({error, isLoadingHotel: false})
        }
    },
    getHotelReviews: async (data) => {
        set((state) => ({
            reviews: {
                ...state.reviews,
                isLoadingReviews: true
            }
        }));
        try {
            if (data.hotelId) {
                const reviews = await hotelApi.getReviews(data.hotelId, data.limit, data.offset)
                const offset = [...get().reviews.items, ...reviews.items].length
                set((state) => ({
                    reviews: {
                        ...state.reviews,
                        items: [...state.reviews.items, ...reviews.items],
                        isLoadingReviews: false,
                        offset: offset,
                    }
                }));
            }
        } catch (error) {
            set((state) => ({
                error,
                reviews: {
                    ...state.reviews,
                    isLoadingReviews: false
                }
            }));
        }
    },
    setRoom: (selectedRoom: Room) => {
        set(() => {
            return {
                selectedRoom
            }
        })
    },
    reset: () => {
        set(initialState)
    },
}))

export default useHotelStore
