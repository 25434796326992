import apiHotel from 'api'
import axios from 'axios'
import { CreateOrderParams } from "./types";

const orderApi = {
    async getOrder(params: { externalId: string }): Promise<string | null> {
        try {
            const response = await apiHotel.get('/order', {
                params: params
            })

            return response.data
        } catch (e) {
            return null
        }
    },
    async createOrder(params: CreateOrderParams): Promise<string | null> {
        try {
            params.user_ip = await this.getIp()
            const response = await apiHotel.post('/createOrder', params)

            return response.data.externalId
        } catch (e) {
            return null
        }
    },
    async getIp(): Promise<string | undefined> {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            return response.data.ip
        } catch (error) {
            console.error('Error fetching IP address:', error);
            return undefined
        }
    }
}

export default orderApi
