"use client";

import { ClockIcon, MapPinIcon } from "@heroicons/react/24/outline";
import { useState, useRef, useEffect, FC } from "react";
import ClearDataButton from "./ClearDataButton";
import { loadCities } from "../../infra/store/cities/index"; // Імпорт функції запиту до API
import countriesObj from "../../infra/store/countries/index"
import useSearchStore from "../../store/searchState";
import CityState from "../../entitites/City_State";

export interface LocationInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
}

const LocationInput: FC<LocationInputProps> = ({
  autoFocus = false,
  placeHolder = "Location",
  desc = "Where are you going?",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);


  const locationError = useSearchStore((state) => state.locationError);
  const recentSearches = useSearchStore((state) => state.recentSearches);
  const setRecentSearches = useSearchStore((state) => state.setRecentSearches);
  const setLocationError = useSearchStore((state) => state.setLocationError);
  const setLocation = useSearchStore((state) => state.setLocation);
  const location = useSearchStore((state) => state.location);
  const [value, setValue] = useState(location.title ?? "");
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [cities, setCities] = useState<CityState[]>([]); // Додаємо стан для міст
  const [isLoading, setIsLoading] = useState(false); // Стан для індикатора завантаження

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  // Функція для перетворення об'єкта у масив міст
  const transformCities = (citiesObj: any) => {
    return Object.values(citiesObj.cities).map((city: any) => ({
      id: city.id,
      title: city.title, // Перетворюємо "name" в "title"
      type: city.type,
      country: countriesObj[city.country_code]?.name ?? city.country_code,
    }));
  };

  // Функція для завантаження міст із API
  const fetchCities = async (query: string) => {
    if (!query) return;
      setIsLoading(true);
    try {
      const citiesResponse = await loadCities(query);
      const transformedCities = transformCities(citiesResponse); // Перетворюємо отримані дані
      console.log("transformedCities", transformedCities);
      setCities(transformedCities); // Встановлюємо перетворені дані в стан
    } catch (error) {
      console.error("Error fetching cities:", error);
      setCities([]); // Якщо помилка, встановлюємо порожній масив
    } finally {
      setIsLoading(false);
    }
  };

  // Виклик функції запиту міст при зміні введеного значення з дебаунсом
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (value && value.length >= 3) {
        fetchCities(value);
      }
    }, 400); // Додаємо затримку для оптимізації запитів
    return () => clearTimeout(delayDebounceFn);
  }, [value]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK INSIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUTSIDE
    setShowPopover(false);
  };

  const handleSelectLocation = (item: CityState) => {
    setLocationError(false);
    setValue(item.title); // Встановлюємо вибрану назву міста
    setShowPopover(false);
    // Тут ви можете передати id на сервер
    console.log("Selected City ID:", item.id);
    setLocation(item);
    setRecentSearches(item);
  };

  const renderSearchValue = () => {
    if (isLoading) {
      return <p className="px-4 sm:px-8">Loading...</p>;
    }

    if (cities.length === 0) {
      return <p className="px-4 sm:px-8">No cities found.</p>;
    }

    return (
      <>
        {cities.map((city) => (
          <span
            onClick={() => handleSelectLocation(city)}
            key={city.id}
            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
          >
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              {city.title}, {city.country}
            </span>
          </span>
        ))}
      </>
    );
  };

  const renderRecentSearches = () => {
    return (
      <>
        <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
          Recent searches
        </h3>
        <div className="mt-2">
          {recentSearches.map((item) => (
            <span
              onClick={() => handleSelectLocation(item)}
              key={item.title}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <ClockIcon className="h-4 sm:h-6 w-4 sm:w-6" />
              </span>
              <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                {item.title}
              </span>
            </span>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
  <div
    onClick={() => setShowPopover(true)}
    className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
      showPopover ? "nc-hero-field-focused" : ""
    }`}
  >
    <div className="text-neutral-300 dark:text-neutral-400">
      <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
    </div>
    <div className="flex-grow">
      <input
        className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate ${
          locationError ? "border-red-500" : ""
        }`}
        value={value}
        autoFocus={showPopover}
        onChange={(e) => {
          setValue(e.currentTarget.value);
          setLocationError(false);
          setLocation({ country: "", id: 0, title: "", type: "" });
        }}
        placeholder={"Search destinations"}
        ref={inputRef}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      />
      {locationError && (
        <span className="text-red-500 text-sm mt-1">
          Please choose a destination to start searching.
        </span>
      )}
      <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
        <span className="line-clamp-1">
          {value.length === 0 ? desc : placeHolder}
        </span>
      </span>
      {value && showPopover && (
        <ClearDataButton
          onClick={() => {
            setValue("");
            setLocation({ country: "", id: 0, title: "", type: "" });
          }}
        />
      )}
    </div>
  </div>

  {showPopover && (
    <div
      className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}
    ></div>
  )}

  {showPopover && (
    <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
      {value ? renderSearchValue() : renderRecentSearches()}
    </div>
  )}
</div>
  );
};

export default LocationInput;
