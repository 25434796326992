import { create } from 'zustand'
import { Hotel, TripDetails } from '../entitites';
import Filters from '../entitites/Filters';
import { loadHotels } from '../infra/store/hotels';

interface HotelsState {
  hotels: Hotel[];
  setHotels: (hotels: Hotel[]) => void;
  trip_param: { offset: number, limit: number };
  setTrip: (trip_param: { offset: number, limit: number }) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  stars_rating: number[];
  setStarsRatings: (ratings: number[]) => void;
  rangePrices: number[];
  setRangePrices: (prices: number[]) => void;
  loadMoreHotels: (trip: TripDetails, filters?: Filters) => Promise<Hotel[]>;
  hasMoreHotels: boolean;
  setHasMoreHotels: (hasMoreHotels: boolean) => void;
}

const useHotelsStore = create<HotelsState>((set, get) => ({
  hotels: [],
  setHotels: (hotels: Hotel[]) => set({ hotels }),
  trip_param: { offset: 0, limit: 16 },
  setTrip: (trip_param) => set({ trip_param }),
  loading: false,
  setLoading: (loading) => set({ loading }),
  stars_rating: [0, 1, 2, 3, 4, 5],
  setStarsRatings: (stars_rating: number[]) => set({ stars_rating }),
  rangePrices: [0, 2000],
  setRangePrices: (rangePrices) => set({ rangePrices }),
  hasMoreHotels: true,
  setHasMoreHotels: (hasMoreHotels: boolean) => set({ hasMoreHotels }),
  // завантаження додаткових готелів
  loadMoreHotels: async (trip, filters?) => {
    const { rangePrices, trip_param, setHasMoreHotels, stars_rating } = get();
    if (!trip.limit) trip.limit = trip_param.limit; 
    if (!trip.offset) trip.offset = trip_param.offset; 
    try {
      // завантаження нових готелів
      const new_hotels = await loadHotels(trip, filters || { stars: stars_rating, priceFrom: rangePrices[0], priceTo: rangePrices[1] });
      if (!new_hotels.length) { // обробка коли завантажені всі готелі
        setHasMoreHotels(false);
        return [];
      }
      // апдейт списку готелів
      set((state) => ({
        hotels: [...state.hotels, ...new_hotels]
      }));
      // апдейт параметрів поїздки
      set((state) => ({
        trip_param: { offset: state.trip_param.offset + new_hotels.length, limit: state.trip_param.limit },
      }));
      return new_hotels;
    } catch (error) {
      console.error("Error loading hotels:", error);
    }
  },
}));

export default useHotelsStore;
