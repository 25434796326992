import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import React, { FC, useEffect, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet-async";
import SectionGridHasMap from "./SectionGridHasMap";
import useSearchStore from "../../store/searchState";

export interface ListingStayPageProps {
  className?: string;
}
export type HotelsTab = "List" | "Map";

const ListingStayPage: FC<ListingStayPageProps> = ({ className = "" }) => {
  const isMap = useSearchStore((state) => state.isMap);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => { window.removeEventListener("resize", handleResize) };
  }, []);

  const checkCondition = (): boolean => {
    if (isMobile) return isMobile;

    return isMap === 'Map';
  };


  return (
    <div
      className={`nc-ListingStayPage relative ${className}`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>Chisfis || Booking React Template</title>
      </Helmet>
      <BgGlassmorphism />



        {checkCondition() === false && (
        <div className="container relative overflow-hidden">
          {/* Секція пошуку готелів */}
          <div>
            <SectionHeroArchivePage
              currentPage="Stays"
              currentTab="Stays"
              className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
            />
            <SectionGridFilterCard className="pb-24 lg:pb-28" />
            </div>
          </div>
        )}
      {checkCondition() === true && (
        <div>
          <div className="container pt-10 pb-24 lg:pt-16 lg:pb-28">
            <SectionHeroArchivePage currentPage="Stays" currentTab="Stays" />
          </div>
          <div className="container pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none">
            <SectionGridHasMap />
          </div>
        </div>
      )}
    </div>
  );
};

export default ListingStayPage;
