import { TripDetails } from "entitites";
import { splitDate } from "infra/utils/date";
import axiosHotels from 'api'
import Filters from "../entitites/Filters";

const hotelApi = {
    async getHotels(trip: TripDetails, filters: Filters): Promise<any> {
        const {
            item_id: city,
            adults,
            children,
            limit,
            offset
        } = trip

        const startDate = splitDate(new Date(trip.startDate));
        const endDate = splitDate(new Date(trip.endDate));
        console.log(startDate, 'loadHotels startDate');
        console.log(endDate, 'loadHotels endDate');

        const response = (await axiosHotels.post('/hotels', {
            endDate,
            startDate,
            city,
            adults,
            children,
            limit,
            offset
        }, {
            params: {
                rating: filters.stars.length ? filters.stars.join(',') : null,
                price_from: filters.priceFrom ? filters.priceFrom : null,
                price_to: filters.priceTo ? filters.priceTo : null
            }
        })).data

        return response
    },
    async getHotel(data: any): Promise<any> {
        const {
            item_id: city,
            hotel,
            adults,
            children,
        } = data

        const startDate = splitDate(new Date(data.startDate));
        const endDate = splitDate(new Date(data.endDate));


        const response = (await axiosHotels.post('/hotel', {
            endDate,
            startDate,
            city,
            hotel,
            adults,
            children,
        })).data

        return response
    },
    async getReviews(hotelId: string, limit: number, offset: number) {
        const response = (await axiosHotels.post('/hotel_reviews', {hotel: hotelId}, {
            params: {
                limit,
                offset
            }
        })).data
        return response
    },
    async getHotelReviews(hotel: string | undefined): Promise<any> {
        const response = (await axiosHotels.post('/hotel_reviews', {hotel})).data
        return response
    },
    async getRoomPhotos(hotel: string): Promise<any> {
        const response = (await axiosHotels.post('/photosroom', {hotel})).data
        return response
    }
}

export default hotelApi
