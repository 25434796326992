import axiosHotels from 'api'

export const DEFAULT_CITY = {
    country_code: "AE",
    id: 6053839,
    title: "Dubai",
    type: "City"
}

export const DEFAULT_CITIES = [
    DEFAULT_CITY,
    {
        id: 4896,
        title: "Cascais",
        type: "City",
        country_code: "PT"
    },
]

/**
 * Example of loading data.
 * In real app data should be loaded from API in Domain layer using Transport
 */
export async function loadCities(query: string = 'en'): Promise<any> {
    const transformedCities = (await axiosHotels.post('/multicomplete', {query, language: 'en'})).data
    console.log(transformedCities, 'transformedCities')
    return transformedCities
}
